import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { Button, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import SolutionImage from '../../assets/images/SolutionImage.image.svg';
import SolutionImageSmall from '../../assets/images/SolutionImageSmall.image.svg';
import ArrowRightImage from '../../assets/images/ArrowRight.image.svg';
import Box from '@mui/material/Box';
import { Link } from 'gatsby';
import { PANEL_REGISTER_PAGE } from '../../cosnts/links';

const StyledContainer = styled(Container)`
    margin-top: 40px;
`;

const StyledStack = styled(Stack)`
    ${({ theme }) => theme.breakpoints.down('md')} {
        margin-top: 30px;
        margin-bottom: 42px;
    }
`;

const StyledHr = styled('hr')`
    border-bottom: 1px solid #e6e6e6;
    border-top: none;
    width: 100%;
`;

const Solution = ({}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledContainer>
            <Stack direction={isMobile ? 'column-reverse' : 'row'} alignItems="center">
                <StyledStack flex={1} gap="22px">
                    <Typography variant="h2">Proste rozwiązanie</Typography>
                    <Typography>
                        Nasza platforma to unikalne rozwiązanie, którego celem jest umożliwienie
                        bieżącego i wydajnego rozliczania dotacji oświatowych. Z nami cały proces
                        stanie się prosty i przyjemny, tym samym pozostawiając w Twoich rękach pełną
                        kontrolę. Nie zwlekaj - wypróbuj już teraz!
                    </Typography>
                    <StyledHr />
                    <Box>
                        <Link to={PANEL_REGISTER_PAGE}>
                            <Button
                                variant="contained"
                                fullWidth={isMobile}
                                endIcon={<ArrowRightImage />}
                            >
                                Wypróbuj za darmo
                            </Button>
                        </Link>
                    </Box>
                </StyledStack>
                {isMobile ? <SolutionImageSmall /> : <SolutionImage />}
            </Stack>
        </StyledContainer>
    );
};

export { Solution };
