import { first } from 'lodash';
import packageJson from '../../package.json'; //potentially exposing packageJson data but 'only default export is available soon' error when trying to get only environment

export type Environment = 'devLocal' | 'devRemote' | 'uat' | 'prod' | undefined;

const { environment } = packageJson;
const envName = first(Object.entries(environment).find(([_, value]) => value)) as Environment;

export const currentEnvName: Exclude<Environment, undefined> =
    typeof envName === 'string' ? envName : 'devRemote';
