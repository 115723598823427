import React from 'react';
import Stack from '@mui/material/Stack';
import { Avatar, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';

const StyledCard = styled(Stack)`
    min-height: 204px;
    background-color: ${({ theme }) => `${theme.palette.background.paper}`};
    padding: 31px 35px;
    align-items: center;
    box-shadow: 0 4px 27px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    ${({ theme }) => theme.breakpoints.down('md')} {
        height: auto;
    }
`;

const Triagle = styled(Box)`
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid white;
`;

type Props = {
    title: string;
    description: string;
    name: string;
    position: string;
};

const ReviewItem = ({ name, position, description, title }: Props) => (
    <Stack flex={1} alignItems="center">
        <StyledCard gap="15px">
            <Typography fontSize="20px" lineHeight="28px" fontWeight={700}>
                "{title}"
            </Typography>
            <Typography>{description}</Typography>
        </StyledCard>
        <Triagle />
        <Stack direction="row" marginTop="16px" gap="16px" alignItems="center">
            <Avatar sx={{ width: 62, height: 62 }} />
            <Stack>
                <Typography variant="h6">{name}</Typography>
                <Typography fontSize="14px" lineHeight="24px">
                    {position}
                </Typography>
            </Stack>
        </Stack>
    </Stack>
);
export { ReviewItem };
