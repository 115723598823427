import React from 'react';
import Container from '@mui/material/Container';
import { styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ReviewItem } from './ReviewItem';

const StyledContainer = styled(Container)`
    margin-top: 84px;
    margin-bottom: 80px;
    ${({ theme }) => theme.breakpoints.down('md')} {
        height: auto;
    }
`;

const Review = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledContainer>
            <Typography variant="h2" color="primary" textAlign="center" marginBottom="74px">
                Co mówią
                <br /> klienci?
            </Typography>
            <Stack direction={isMobile ? 'column' : 'row'} gap="48px">
                <ReviewItem
                    title="Tego szukałem!"
                    description="Od dawna poszukiwałem na rynku podobnego rozwiązania, aby móc rozliczać i kontrolować
                wydatki w ramach dotacji na bieżąco. Teraz to proste, nic dodać, nic ująć!"
                    name="Adam Kowalski"
                    position="Dyrektor"
                />
                <ReviewItem
                    title="Kontrola odzyskana"
                    description="Mieliśmy problemy z kontrolą wydatkowania dotacji, co powodowało niejednokrotnie
                problemy na koniec roku - obecnie na bieżąco widzimy rozliczenie dotacji, planujemy
                wydatki świadomie i nasze problemy przestały istnieć."
                    name="Magdalena Nowak"
                    position="Intendentka"
                />
            </Stack>
        </StyledContainer>
    );
};

export { Review };
