import React from 'react';
import ELFLogo from '../../assets/images/logoELFLong.image.svg';
import { Logo, MobileAuthNavbarRow, MobileLogoIconContainer, MobileMenuButton } from './Components';
import MenuIcon from '../../assets/icons/MenuIcon.image.svg';
import { Menu, MenuItem, Typography } from '@mui/material';
import { Link } from 'gatsby';
import { PANEL_LOGIN_PAGE, PANEL_REGISTER_PAGE } from '../../cosnts/links';

type MobileAuthNavbarProps = {
    openNavMenu: (event: React.MouseEvent<HTMLElement>) => void;
    anchorElNav: null | HTMLElement;
    closeNavMenu: () => void;
};
const MobileNavbar = ({ openNavMenu, anchorElNav, closeNavMenu }: MobileAuthNavbarProps) => {
    return (
        <MobileAuthNavbarRow>
            <MobileLogoIconContainer>
                <Logo>
                    <ELFLogo />
                </Logo>
            </MobileLogoIconContainer>

            <MobileMenuButton size="large" color="inherit" onClick={openNavMenu}>
                <MenuIcon />
            </MobileMenuButton>

            <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={closeNavMenu}
                componentsProps={{
                    backdrop: { style: { backgroundColor: 'transparent' } },
                }}
            >
                <MenuItem component={Link} to={'/#hero'}>
                    <Typography textAlign="center">O produkcie</Typography>
                </MenuItem>
                <MenuItem component={Link} to={'/#subscription'}>
                    <Typography textAlign="center">Płatność</Typography>
                </MenuItem>
                <MenuItem component={Link} to={'/#contact'}>
                    <Typography textAlign="center">Kontakt</Typography>
                </MenuItem>
                <MenuItem component={Link} to={PANEL_LOGIN_PAGE}>
                    <Typography textAlign="center">Logowanie</Typography>
                </MenuItem>
                <MenuItem component={Link} to={PANEL_REGISTER_PAGE}>
                    <Typography textAlign="center">Rejestracja</Typography>
                </MenuItem>
            </Menu>
        </MobileAuthNavbarRow>
    );
};
export default MobileNavbar;
