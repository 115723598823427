import React from 'react';
import Stack from '@mui/material/Stack';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import ArrowRightImage from '../../assets/images/ArrowRight.image.svg';
import { PANEL_REGISTER_PAGE } from '../../cosnts/links';
import { StyledContainer, StyledImageStack } from './Header.styled';

type Props = {};

const Header = ({}: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledContainer>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                gap={isMobile ? '77px' : '50px'}
                justifyContent="flex-end"
                marginRight={isMobile ? 'unset' : '-200px'}
            >
                <Stack gap="29px" flex={1} justifyContent="center" flexWrap="wrap">
                    <Typography variant="h1">
                        Rozliczanie dotacji jeszcze nigdy nie było takie proste
                    </Typography>
                    <Typography variant="h6">
                        Zaoszczędź czas i pieniądze dzięki ELF EDU - dedykowanej platformie do
                        rozliczania dotacji oświatowych!
                    </Typography>
                    <Box>
                        <Link to={PANEL_REGISTER_PAGE}>
                            <Button variant="contained" endIcon={<ArrowRightImage />}>
                                Wypróbuj za darmo
                            </Button>
                        </Link>
                    </Box>
                </Stack>
                <StyledImageStack flex={2}>
                    {isMobile ? (
                        <StaticImage
                            src="../../assets/images/HeaderMobileImage.png"
                            alt="HeaderMobileImage"
                        />
                    ) : (
                        <StaticImage
                            src="../../assets/images/HeaderImage.png"
                            alt="HeaderImage"
                            layout="fullWidth"
                        />
                    )}
                </StyledImageStack>
            </Stack>
        </StyledContainer>
    );
};

export { Header };
