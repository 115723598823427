import { currentEnvName } from '../configs/currentEnviroment';

const PANEL_URLS = {
    devLocal: 'http://localhost:3000',
    devRemote: 'https://dev.dotacje.softwarepartner.pl',
    uat: 'https://uat.dotacje.softwarepartner.pl',
    prod: 'https://panel.elfedu.pl',
};

export const PANEL_URL = PANEL_URLS[currentEnvName];

export const PANEL_REGISTER_PAGE = `${PANEL_URL}/register`;
export const PANEL_LOGIN_PAGE = `${PANEL_URL}/login`;

export const PRIVACY_POLICY = 'https://dotacje-documents.s3.eu-central-1.amazonaws.com/Elf-Edu-Polityka-Prywatnosci.pdf'
export const RULES = 'https://dotacje-documents.s3.eu-central-1.amazonaws.com/Elf-Edu-Regulamin.pdf'