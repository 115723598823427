import { Box, Icon, IconButton, Button as MuiButton } from '@mui/material';
import { styled } from '@mui/system';
import Container from '@mui/material/Container';
import { Link } from 'gatsby';

export const NavbarRow = styled(Box)`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
`;

export const LogoIconContainer = styled(Box)`
    margin-right: 100px;
    &:hover {
        cursor: pointer;
    }
`;

export const Logo = styled(Icon)`
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: black;
`;

export const Button = styled(MuiButton)((props: { styledColor?: string }) => ({
    margin: '8px',
    color: props.styledColor,
}));

export const ButtonIconContainer = styled(Box)`
    text-align: center;
    width: 24px;
    height: 26px;
    margin-right: 7px;
`;

export const AuthBox = styled(Box)`
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: rgb(121, 123, 137, 0.1);
    background-color: ${({ theme }) => `${theme.palette.background.default}`};
    position: fixed;
    width: 100%;
    z-index: 100;
`;

export const AuthNavbarButton = styled(MuiButton)`
    margin: 8px;
    padding-top: 13px;
    padding-bottom: 13px;
    border-radius: 8px;
    font-weight: 500;
    color: black;
    text-transform: capitalize;
    text-decoration: none;
`;

export const AuthNavbarContactButton = styled(Link)`
    margin-right: auto;
`;

export const AuthNavbarRegisterButton = styled(AuthNavbarButton)`
    padding-right: 47px;
    padding-left: 47px;
    color: white;
`;

export const MobileAuthNavbarRow = styled(Container)`
    padding-right: 16px;
    flex-direction: row;
    padding-top: 34px;
    padding-bottom: 20px;
    display: flex;
    position: fixed;
    z-index: 100;
    background-color: ${({ theme }) => `${theme.palette.background.default}`};
    svg {
        height: 26px;
    }
`;

export const MobileLogoIconContainer = styled(LogoIconContainer)`
    margin-right: 0;
`;

export const MobileMenuButton = styled(IconButton)`
    margin-left: auto;
`;
