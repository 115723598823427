import React from 'react';
import Box from '@mui/material/Box';
import { Button, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ProsItem } from './ProsItem';
import Container from '@mui/material/Container';
import Security from '../../assets/images/Security.image.svg';
import Chart from '../../assets/images/Chart.image.svg';
import Page from '../../assets/images/Page.image.svg';
import { Link } from 'gatsby';
import { PANEL_REGISTER_PAGE } from '../../cosnts/links';

const StyledBox = styled(Box)`
    padding: 90px 0 70px;
    background-color: ${({ theme }) => `${theme.palette.background.paper}`};
`;

const BlueBox = styled(Stack)`
    background-color: ${({ theme }) => `${theme.palette.primary.main}`};
    margin-top: 19px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
`;

const Pros = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledBox>
            <Container>
                <Typography variant="h3" textAlign="center">
                    Dlaczego warto?
                </Typography>
                <Stack direction={isMobile ? 'column' : 'row'} gap="52px" marginTop="46px">
                    <ProsItem
                        icon={<Page />}
                        title="Oszczędność czasu"
                        details="Szybko, intuicyjnie, o każdej porze - tak wygląda rozliczanie dotacji z ELF EDU! Nie potrzebujesz wykwalifikowanych specjalistów."
                    />
                    <ProsItem
                        icon={<Chart />}
                        title="Tańsze rozwiązanie"
                        details="Przerażają Cię koszty bieżącego rozliczania dotacji oświatowych? Z nami zaoszczędzisz pieniądze, a  koszt systemu rozliczysz w dotacji!"
                    />
                    <ProsItem
                        icon={<Security />}
                        title="Wszystko pod kontrolą"
                        details="Bądź na bieżąco z rozliczaniem dotacji oświatowej, kontroluj wydatki i planuj dysponowanie środkami - teraz to proste!"
                    />
                </Stack>
                <BlueBox>
                    <Stack direction={isMobile ? 'column' : 'row'} gap="15px" alignItems="center">
                        <Typography color="white">
                            Chcesz przetestować za darmo? Kliknij tutaj!
                        </Typography>
                        <Link to={PANEL_REGISTER_PAGE}>
                            <Button variant="outlined" fullWidth={isMobile}>
                                Sprawdź
                            </Button>
                        </Link>
                    </Stack>
                </BlueBox>
            </Container>
        </StyledBox>
    );
};

export { Pros };
