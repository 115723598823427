import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const DefaultHead = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `);

    const metadata = data.site.siteMetadata;

    const schema = { 
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Kłodzko",
            "streetAddress": "Zajęcza 4",
            "postalCode": "57-300",
            "addressRegion": "dolnośląskie"
        },
        "name": "ELF EDU",
        "email": "kontakt@elfedu.pl",
        "telephone": "696607793",
        "vatID": "8831752764",
        "image": "https://elfedu.pl/static/e0c6c4a9f09886f1288727bc4cdcbdcf/21904/HeaderImage.png"
    };

    const schemaAsString = JSON.stringify(schema, null, 2);

    return (
        <>
            <title>{metadata.title}</title>
            <meta name="description" content={metadata.description} />
            <script type="application/ld+json">{schemaAsString}</script>
        </>
    );
};

export default DefaultHead;
