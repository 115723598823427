import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Button, List, ListItem, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowRightImage from '../../assets/images/ArrowRight.image.svg';
import CheckIcon from '../../assets/icons/check.image.svg';
import { Link } from 'gatsby';
import { PANEL_REGISTER_PAGE } from '../../cosnts/links';

const StyledBox = styled(Box)`
    padding-top: 197px;
    padding-bottom: 197px;
    background-color: ${({ theme }) => `${theme.palette.primary.main}`};
`;

const StyledCard = styled(Stack)`
    padding: 44px 76px;
    width: 457px;
    background-color: rgba(246, 246, 251, 0.4);
    border: 1px solid #1f64ff;
    border-radius: 10px;
    align-items: center;
    gap: 23px;
    ${({ theme }) => theme.breakpoints.down('md')} {
        width: 100%;
        padding: 40px 15px;
    }
`;

const TextWithOpacity = styled(Typography)`
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.3) !important;
`;

const PRICE = 699;

const Subscription = ({}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledBox id="subscription">
            <Container>
                <Stack
                    direction={isMobile ? 'column-reverse' : 'row'}
                    alignItems={isMobile ? 'center' : 'initial'}
                    gap="44px"
                >
                    <StyledCard>
                        <Typography variant="h4" color="white">
                            Licencja
                        </Typography>
                        <Typography variant="h5" color="white">
                            Jedna placówka
                        </Typography>
                        <Stack direction="row">
                            <TextWithOpacity>zł</TextWithOpacity>
                            <Typography
                                color="white"
                                fontWeight={700}
                                fontSize="64px"
                                lineHeight="83px"
                            >
                                {PRICE}
                            </Typography>
                        </Stack>
                        <List>
                            <ListItem>
                                <CheckIcon />
                                <Typography marginLeft="10px" color="white">
                                    Nielimitowana ilość dokumentów
                                </Typography>
                            </ListItem>
                            <ListItem color="white">
                                <CheckIcon />
                                <Typography marginLeft="10px" color="white">
                                    Generowanie raportów
                                </Typography>
                            </ListItem>
                            <ListItem color="white">
                                <CheckIcon />
                                <Typography marginLeft="10px" color="white">
                                    Zarządzanie użytkownikami
                                </Typography>
                            </ListItem>
                            <ListItem color="white">
                                <CheckIcon />
                                <Typography marginLeft="10px" color="white">
                                    Dostępność zawsze i wszędzie
                                </Typography>
                            </ListItem>
                            <ListItem color="white">
                                <CheckIcon />
                                <Typography marginLeft="10px" color="white">
                                    Obsługa wielu placówek
                                </Typography>
                            </ListItem>
                        </List>
                        <Link to={PANEL_REGISTER_PAGE}>
                            <Button variant="contained" fullWidth endIcon={<ArrowRightImage />}>
                                Rozpocznij
                            </Button>
                        </Link>
                    </StyledCard>
                    <Stack
                        justifyContent="center"
                        alignItems={isMobile ? 'center' : 'initial'}
                        maxWidth="534px"
                    >
                        <Typography variant="h2" color="white" marginBottom="22px">
                            Proste zasady
                        </Typography>
                        <Typography
                            variant="h3"
                            color="white"
                            textAlign={isMobile ? 'center' : 'initial'}
                        >
                            Płacisz tylko raz i korzystasz cały czas, bez limitów. Wypróbuj!
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </StyledBox>
    );
};

export { Subscription };
