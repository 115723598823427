import React from 'react';
import Box from '@mui/material/Box';
import { TextField, styled, Typography, useTheme, useMediaQuery } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import HomeIcon from '../../assets/icons/home.image.svg';
import PhoneIcon from '../../assets/icons/phone.image.svg';
import MailIcon from '../../assets/icons/mail.image.svg';
import LogoWhiteIcon from '../../assets/icons/logoWhite.image.svg';
import LogoELF from '../../assets/images/logoELFLong.image.svg';
import { Link } from 'gatsby-theme-material-ui';
import {PRIVACY_POLICY, RULES} from "../../cosnts/links";

const StyledBox = styled(Box)`
    background-color: ${({ theme }) => `${theme.palette.primary.dark}`};
    padding-top: 139px;
`;

const StyledStack = styled(Stack)`
    background-color: #8082a0;
    height: 68px;
    border-radius: 15px;
    padding: 6px 9px 6px 20px;
    > button {
        width: 140px;
    }
`;

const StyledTextField = styled(TextField)`
    > p {
        margin-top: 10px;
        font-size: 12px;
        letter-spacing: -0.03em;
        color: rgba(255, 255, 255, 0.8);
        margin-left: -15px;
        margin-right: -50px;
    }
    input {
        height: 46px;
        color: white;
    }
    :before,
    :after {
        border: none;
    }
`;

const Header = styled(Typography)`
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: white;
`;

const StyledLink = styled(Link)`
    color: white;
    cursor: pointer;
    font-size: 14px;
`;

const ImageBox = styled(Box)`
    color: white;
    ${({ theme }) => theme.breakpoints.down('md')} {
        padding-bottom: 60px;
        text-align: center;
    }
`;

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledBox id={'contact'}>
            <Container>
                <Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between">
                    {/*<Typography variant="h2" color="white" marginBottom="30px">*/}
                    {/*    Nie przegap nowych, ciekawych informacji!*/}
                    {/*</Typography>*/}
                    {/*<StyledStack direction="row" gap="9px">*/}
                    {/*    <StyledTextField*/}
                    {/*        fullWidth*/}
                    {/*        placeholder="Pozostaw swój adres e-mail"*/}
                    {/*        variant="standard"*/}
                    {/*        helperText="Zapisując się do newlettera akceptujesz Regulamin i Politykę Prywatności.."*/}
                    {/*    />*/}
                    {/*    <Button variant="contained">Wyślij</Button>*/}
                    {/*</StyledStack>*/}
                    <ImageBox>
                        <LogoELF />
                    </ImageBox>
                    <Stack direction={isMobile ? 'column' : 'row'} gap="46px">
                        <Stack gap="20px" alignItems={isMobile ? 'center' : 'initial'}>
                            <Header>Produkt</Header>
                            <StyledLink to="/#subscription">Płatność</StyledLink>
                            <StyledLink to="/#hero">O produkcie</StyledLink>
                        </Stack>
                        <Stack gap="20px" alignItems={isMobile ? 'center' : 'initial'}>
                            <Header>Firma</Header>
                            <StyledLink target="_blank" rel="noreferrer" to={PRIVACY_POLICY}>Polityka prywatności</StyledLink>
                            <StyledLink target="_blank" rel="noreferrer" to={RULES}>Regulamin</StyledLink>
                        </Stack>
                        <Stack gap="20px" alignItems={isMobile ? 'center' : 'initial'}>
                            <Header>Kontakt</Header>
                            <Stack direction="row" gap="22px">
                                <HomeIcon />
                                <Typography fontSize="14px" color="white">
                                    AS Pro-Tech Magdalena Sobczyk <br />
                                    ul. Zajęcza 4, 57-300 Kłodzko <br />
                                    NIP 8831752764
                                </Typography>
                            </Stack>
                            <Stack direction="row" gap="22px">
                                <PhoneIcon />
                                <Typography fontSize="14px" color="white">
                                    +48 696 607 793 <br />
                                    +48 533 423 355 <br />
                                    (74) 810 83 41
                                </Typography>
                            </Stack>
                            <Stack direction="row" gap="22px">
                                <MailIcon />
                                <Typography fontSize="14px" color="white">
                                    kontakt@elfedu.pl
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    marginTop="100px"
                    paddingBottom="60px"
                    gap="10px"
                    justifyContent={isMobile ? 'center' : 'flex-start'}
                >
                    <Typography color="white">powered by</Typography>
                    <LogoWhiteIcon />
                </Stack>
            </Container>
        </StyledBox>
    );
};

export { Footer };
