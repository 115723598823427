import React from 'react';
import ELFLogo from '../../assets/images/logoELFLong.image.svg';
import {
    AuthBox,
    AuthNavbarButton,
    AuthNavbarContactButton,
    AuthNavbarRegisterButton,
    Logo,
    LogoIconContainer,
    NavbarRow,
} from './Components';
import MobileAuthNavbar from './MobileNavbar';
import Container from '@mui/material/Container';
import { Link } from 'gatsby';
import { PANEL_LOGIN_PAGE, PANEL_REGISTER_PAGE } from '../../cosnts/links';

type AuthNavbarProps = {
    onLoginButtonClick?: () => void;
    onRegisterButtonClick?: () => void;
    isMobile?: boolean;
    openNavMenu: (event: React.MouseEvent<HTMLElement>) => void;
    anchorElNav: null | HTMLElement;
    closeNavMenu: () => void;
};

const Navbar = ({ isMobile, openNavMenu, anchorElNav, closeNavMenu }: AuthNavbarProps) => {
    return !isMobile ? (
        <>
            <AuthBox>
                <Container>
                    <NavbarRow>
                        <LogoIconContainer>
                            <Logo>
                                <ELFLogo />
                            </Logo>
                        </LogoIconContainer>
                        <Link to={'/#hero'}>
                            <AuthNavbarButton variant="text">O produkcie</AuthNavbarButton>
                        </Link>
                        <Link to={'/#subscription'}>
                            <AuthNavbarButton variant="text">Płatności</AuthNavbarButton>
                        </Link>
                        <AuthNavbarContactButton to={'/#contact'}>
                            <AuthNavbarButton variant="text">Kontakt</AuthNavbarButton>
                        </AuthNavbarContactButton>
                        <Link to={PANEL_LOGIN_PAGE}>
                            <AuthNavbarButton variant="text">Logowanie</AuthNavbarButton>
                        </Link>
                        <Link to={PANEL_REGISTER_PAGE}>
                            <AuthNavbarRegisterButton variant="contained">
                                Rejestracja
                            </AuthNavbarRegisterButton>
                        </Link>
                    </NavbarRow>
                </Container>
            </AuthBox>
        </>
    ) : (
        <MobileAuthNavbar
            anchorElNav={anchorElNav}
            closeNavMenu={closeNavMenu}
            openNavMenu={openNavMenu}
        />
    );
};
export default Navbar;
