import { Container, Stack, styled } from '@mui/material';

export const StyledContainer = styled(Container)`
    margin-top: 90px;

    ${({ theme }) => theme.breakpoints.down('md')} {
        margin-top: 150px;
    }
`;

export const StyledImageStack = styled(Stack)`
    ${({ theme }) => theme.breakpoints.down('md')} {
        margin-right: -24px;
        margin-left: -24px;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin-right: -16px;
        margin-left: -16px;
    }
`;
