import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled, Typography } from '@mui/material';

const OverflowBox = styled(Box)`
    width: 78px;
    overflow: hidden;
    margin-bottom: -40px;
    margin-left: 30px;
`;

const ImageBackground = styled(Box)`
    z-index: 2;
    background-color: ${({ theme }) => `${theme.palette.secondary.light}`};
    width: 78px;
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    :after {
        content: '';
        width: 30px;
        height: 30px;
        position: absolute;
        transform: rotate(45deg);
        bottom: -15px;
        right: -15px;
        background-color: #fff;
    }
`;

const BorderBox = styled(Stack)`
    height: 100%;
    z-index: 1;
    background-color: ${({ theme }) => `${theme.palette.background.paper}`};
    border: 1px solid #e9e9e9;
    padding: 60px 36px 31px;
    min-height: 241px;
    position: relative;
    :after {
        content: '';
        width: 50px;
        height: 50px;
        position: absolute;
        transform: rotate(45deg);
        bottom: -25px;
        right: -25px;
        background-color: #fff;
    }
`;

const Line = styled('hr')`
    z-index: 2;
    width: 75px;
    border-top: none;
    border-bottom: 1px solid #e9e9e9;
    transform: rotate(-45deg);
    position: absolute;
    bottom: 0;
    right: -12px;
`;

type Props = {
    icon: ReactNode;
    title: string;
    details: string;
};

const ProsItem = ({ icon, title, details }: Props) => (
    <Stack flex={1} alignSelf={'stretch'} overflow={'hidden'}>
        <OverflowBox>
            <ImageBackground>{icon}</ImageBackground>
        </OverflowBox>
        <BorderBox>
            <Typography variant="h4" marginBottom="22px">
                {title}
            </Typography>
            <Typography>{details}</Typography>
            <Line />
        </BorderBox>
    </Stack>
);

export { ProsItem };
