import { useState } from 'react';
import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header, Pros, Solution, Subscription, Review } from '../components';
import { Footer } from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import CookieConsent from 'react-cookie-consent';
import DefaultHead from '../components/Head/Head';

const pageStyles = {
    overflow: 'hidden',
    color: '#232129',
    fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const IndexPage = () => {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const openNavMenu = (event: React.MouseEvent<HTMLElement>) =>
        setAnchorElNav(event.currentTarget);
    const closeNavMenu = () => setAnchorElNav(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <CookieConsent
                location="bottom"
                buttonText="Akceptuję"
                declineButtonText="Odmawiam"
                cookieName="gatsby-gdpr-google-analytics"
            >
                <Typography color={'white'}>Ta strona korzysta z plików cookies.</Typography>
                <Typography variant="body2">
                    W celu zapewnienia poprawnego działania strony oraz w celach statystycznych,
                    które pomagają w ulepszeniu witrynt.
                </Typography>
            </CookieConsent>
            <main id="hero" style={pageStyles}>
                <Navbar
                    openNavMenu={openNavMenu}
                    anchorElNav={anchorElNav}
                    closeNavMenu={closeNavMenu}
                    isMobile={isMobile}
                />
                <Header />
                <Pros />
                <Solution />
                <Subscription />
                <Review />
            </main>
            <footer>
                <Footer />
            </footer>
        </>
    );
};

export default IndexPage;

export const Head: HeadFC = () => <DefaultHead />;
